import gsap from "gsap";

export function AnimateSection(){
    gsap.from('.fade-in', {
        opacity: 0,
        duration: .3,
        y: 50,
        stagger: .1
    })
    gsap.to('.fade-in', {
        opacity: 1,
        duration: .3,
        y: 0,
        stagger: .1
    })
}