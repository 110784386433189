import LinkIcon from "./icons/LinkIcon";

export default function ({work, activeCategory}){
    return (
        <div className={`rounded-xl p-4 fade-in ${work.bgClass} ${activeCategory === 'All' || work.category === activeCategory ? 'block' : 'hidden'}`}>
            <div className="relative rounded-xl overflow-hidden group">
                <img loading="lazy" src={work.image}
                     className="w-full h-48 object-cover group-hover:scale-110 transition" alt="" />
                <a href="#"
                   className="absolute block inset-0 bg-black/50 flex items-center justify-center text-white opacity-0 group-hover:opacity-100 transition">
                    <LinkIcon/>
                </a>
            </div>

            <h3 className="text-gray-700 dark:text-slate-300 font-medium mt-4 text-lg hover:text-primary-500 transition">
                <a href="#">
                    {work.name}
                </a>
            </h3>
            <p className="text-sm text-gray-400">
                {work.category}
            </p>
        </div>
    )
}