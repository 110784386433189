export default function ({exp}){
    return (
        <div className="rounded-2xl lg:p-5 p-4 bg-blue-50 dark:bg-slate-800 shadow-sm">
            <h4 className="text-gray-700 dark:text-slate-300 text-2xl font-semibold">{exp.title}</h4>
            <h3 className="text-gray-500 dark:text-slate-400 text-lg italic mt-2">{exp.company}</h3>
            <p className=" text-gray-500 dark:text-slate-400 text-xs">
                {exp.years}
            </p>
        </div>
    )
}