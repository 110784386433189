import {nanoid} from "nanoid";
import SectionTitle from "../components/SectionTitle";
import SubTitle from "../components/SubTitle";
import {useEffect} from "react";
import {AnimateSection} from "../AnimateSection";
import Education from "../components/Education";
import Expertise from "../components/Expertise";
import Skill from "../components/Skill";
import Knowledge from "../components/Knowledge";
import Experience from "../components/Experience";

export default function Resume() {

    const experiences = [
        {
            id: nanoid(),
            title: 'Senior Developer',
            years: '2018 - 2020',
            company: 'Google Inc.'
        },
        {
            id: nanoid(),
            title: 'Senior Developer',
            years: '2018 - 2020',
            company: 'Twitter Inc.'
        },
        {
            id: nanoid(),
            title: 'Senior Developer',
            years: '2018 - 2020',
            company: 'Facebook Inc.'
        },
    ]

    const educations = [
        {
            id: nanoid(),
            degree: 'Computer Science',
            years: '2018 - 2020',
            institute: 'Harvard Institute'
        },
        {
            id: nanoid(),
            degree: 'PHD in Art',
            years: '2020 - 2021',
            institute: 'North Institute'
        },
        {
            id: nanoid(),
            degree: 'CSE Degree',
            years: '2022 - 2023',
            institute: 'Harvard Institute'
        },
    ]

    const skills = [
        {
            id: nanoid(),
            bgClass: 'bg-red-400',
            name: 'HTML',
            percent: 95
        },
        {
            id: nanoid(),
            bgClass: 'bg-indigo-400',
            name: 'CSS',
            percent: 98
        },
        {
            id: nanoid(),
            bgClass: 'bg-blue-400',
            name: 'JavaScript',
            percent: 90
        },
        {
            id: nanoid(),
            bgClass: 'bg-emerald-400',
            name: 'Vue.JS',
            percent: 95
        },
    ]

    const knowledge = ['HTML', 'CSS', 'JavaScript', 'Vue.JS', 'Alpine.JS', 'React.JS', 'Laravel', 'TailwindCSS', 'Bootstrap', 'PHP']

    useEffect(() => {
        AnimateSection()
    }, [])

    return (
        <div id="resume" className="scroll-mt-24">
            <SectionTitle>
                My Resume
            </SectionTitle>

            <div className="section_wrapper fade-in">
                <div className="fade-in">
                    <SubTitle>
                        Education
                    </SubTitle>

                    <div className="grid sm:grid-cols-3 lg:gap-4 gap-4 mt-5">
                        {
                            educations.map(edu => (
                                <Education edu={edu} key={edu.id}/>
                            ))
                        }
                    </div>
                </div>

                <div className="fade-in">
                    <SubTitle>
                        Experiences
                    </SubTitle>

                    <div className="grid sm:grid-cols-3 lg:gap-4 gap-4 mt-5">
                        {
                            experiences.map(exp => (
                                <Experience key={exp.id} exp={exp}/>
                            ))
                        }
                    </div>
                </div>

                <div className="mt-5 grid sm:grid-cols-2 gap-8 fade-in">
                    {/*Skills*/}
                    <div>
                        <SubTitle>
                            Skills
                        </SubTitle>

                        <div className="space-y-4 mt-5">
                            {
                                skills.map(skill => (
                                    <Skill key={skill.id} skill={skill}/>
                                ))
                            }
                        </div>
                    </div>

                    {/*Knowledge*/}
                    <div>
                        <SubTitle>
                            Knowledge
                        </SubTitle>

                        <div className="mt-5 flex gap-2 flex-wrap">
                            {
                                knowledge.map((k, index) => (
                                    <Knowledge key={index} k={k}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}