import CodeIcon from "./icons/CodeIcon";
import FacebookIcon from "./icons/FacebookIcon";
import TwitterIcon from "./icons/TwitterIcon";
import InstagramIcon from "./icons/InstagramIcon";
import LinkedinIcon from "./icons/LinkedinIcon";
import PhoneIcon from "./icons/PhoneIcon";
import LocationIcon from "./icons/LocationIcon";
import EmailIcon from "./icons/EmailIcon";
import CalendarIcon from "./icons/CalendarIcon";
import DownloadIcon from "./icons/DownloadIcon";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {AnimateSection} from "../AnimateSection";

export default function SideBar() {
    const location = useLocation()

    useEffect(() => {
        AnimateSection()
    }, [])
    return (
        <>
            <div
                className={`xl:w-96 lg:w-80 w-full flex-shrink-0 bg-slate-50 dark:bg-slate-900 rounded-2xl pb-8 px-8 mt-40 sticky top-40 slide-left ${location.pathname !== '/' ? 'hidden lg:block' : ''}`}>

                {/* User info */}
                <img src="/images/person.jpg" alt="User"
                     className="w-40 h-40 object-cover rounded-full drop-shadow-xl -mt-28 mx-auto ring-4 ring-primary-700"/>
                <h2 className="text-center text-3xl font-semibold mt-4 dark:text-slate-200 text-gray-800">
                    Elizabeth Olsen
                </h2>
                <div className="flex items-center justify-center dark:text-gray-300 text-gray-500 mt-2">
                    <CodeIcon/>
                    Programmer
                </div>

                {/*Share widget*/}
                <div className="flex items-center gap-4 justify-center mt-5">
                    <a href="#"
                       className="w-8 h-8 bg-[#1773EA] text-white rounded flex items-center justify-center shadow-lg hover:scale-110 transition duration-300">
                        <FacebookIcon/>
                    </a>
                    <a href="#"
                       className="w-8 h-8 bg-[#1C9CEA] text-white rounded flex items-center justify-center shadow-lg hover:scale-110 transition duration-300">
                        <TwitterIcon/>
                    </a>
                    <a href="#"
                       className="w-8 h-8 bg-[#e14a84] text-white rounded flex items-center justify-center shadow-lg hover:scale-110 transition duration-300">
                        <InstagramIcon/>
                    </a>
                    <a href="#"
                       className="w-8 h-8 bg-[#0072b1] text-white rounded flex items-center justify-center shadow-lg hover:scale-110 transition duration-300">
                        <LinkedinIcon/>
                    </a>
                </div>

                {/*User contact*/}
                <div
                    className="space-y-3 mt-8 px-5 py-6 bg-blue-50 dark:bg-slate-800 divide-y divide-dashed divide-y-gray-100 dark:divide-slate-600 rounded-xl">
                    <div className="flex items-center rounded-lg">
                        <div
                            className="w-10 h-10 bg-white dark:bg-slate-900 shadow flex items-center justify-center rounded-full flex-shrink-0 text-blue-700">
                            <PhoneIcon/>
                        </div>
                        <div className="pl-4">
                            <p className="text-sm text-gray-500">
                                Phone
                            </p>
                            <h4 className="text-gray-700 dark:text-gray-300 font-medium">
                                +123 456 7890
                            </h4>
                        </div>
                    </div>

                    <div className="flex items-center pt-3">
                        <div
                            className="w-10 h-10 bg-white dark:bg-slate-900 shadow flex items-center justify-center rounded-full flex-shrink-0 text-green-700">
                            <LocationIcon/>
                        </div>
                        <div className="pl-4">
                            <p className="text-sm text-gray-500">
                                Location
                            </p>
                            <h4 className="text-gray-700 dark:text-gray-300 font-medium">
                                Los Angels, US
                            </h4>
                        </div>
                    </div>

                    <div className="flex items-center pt-3">
                        <div
                            className="w-10 h-10 bg-white dark:bg-slate-900 shadow flex items-center justify-center rounded-full flex-shrink-0 text-pink-600">
                            <EmailIcon/>
                        </div>
                        <div className="pl-4">
                            <p className="text-sm text-gray-500">
                                Email
                            </p>
                            <h4 className="text-gray-700 dark:text-gray-300 font-medium">
                                example@mail.com
                            </h4>
                        </div>
                    </div>

                    <div className="flex items-center pt-3">
                        <div
                            className="w-10 h-10 bg-white dark:bg-slate-900 shadow flex items-center justify-center rounded-full flex-shrink-0 text-yellow-500">
                            <CalendarIcon/>
                        </div>
                        <div className="pl-4">
                            <p className="text-sm text-gray-500">
                                Birthday
                            </p>
                            <h4 className="text-gray-700 dark:text-gray-300 font-medium">
                                Jan 20, 1995
                            </h4>
                        </div>
                    </div>
                </div>

                {/*User cv download*/}
                <div className="mt-8">
                    <button type="button"
                            className="flex w-full justify-center items-center rounded-md border border-transparent bg-primary-500 px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition">
                        <DownloadIcon/>
                        Download CV
                    </button>
                </div>
            </div>
        </>
    )
}