import {useEffect, useRef, useState} from "react";
import BarIcon from "./icons/BarIcon";
import HomeIcon from "./icons/HomeIcon";
import Navlink from "./Navlink";
import MailIcon from "./icons/MailIcon";
import UserIcon from "./icons/UserIcon";
import LightIcon from "./icons/LightIcon";
import MoonIcon from "./icons/MoonIcon";
import ResumeIcon from "./icons/ResumeIcon";
import BagIcon from "./icons/BagIcon";

export default function NavBar(props) {
    const {darkMode, toggleDarkMode} = props
    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const mobileBar = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (mobileBar.current && !mobileBar.current.contains(event.target)) {
                setTimeout(() => {
                    setShowMobileMenu(false)
                }, 100)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mobileBar]);

    return (
        <header
            className="backdrop-blur-sm bg-black/50 py-5 sticky top-0 z-40 slide-down">
            <div className="container flex items-center justify-between relative">
                <div ref={mobileBar} className="block lg:hidden">
                    <button onClick={() => setShowMobileMenu(!showMobileMenu)}
                            className="w-10 h-10 border border-white rounded-lg text-white flex items-center justify-center">
                        <BarIcon/>
                    </button>
                </div>
                <nav
                    className={`flex flex-col lg:flex-row gap-4 lg:gap-8 lg:static absolute top-full left-4 bg-white lg:bg-transparent lg:p-0 px-4 py-3 w-44 lg:w-auto shadow-xl lg:shadow-none rounded-lg lg:rounded-none opacity-0 invisible lg:opacity-100 lg:visible mt-4 lg:mt-0 transition duration-300 lg:transition-none ${showMobileMenu && '!opacity-100 !visible'}`}>
                    <Navlink href="/">
                        <HomeIcon/>
                        Home
                    </Navlink>
                    <Navlink href="/about">
                        <UserIcon/>
                        About
                    </Navlink>
                    <Navlink href="/resume">
                        <ResumeIcon/>
                        Resume
                    </Navlink>
                    <Navlink href="works">
                        <BagIcon/>
                        Works
                    </Navlink>
                    <Navlink href="/contact">
                        <MailIcon/>
                        Contact
                    < /Navlink>
                </nav>
                <div className="flex items-center gap-5">
                    <button
                        className="font-roboto bg-primary-500 hover:bg-primary-600 transition text-white px-8 py-2 rounded-lg font-medium uppercase">
                        Hire Me
                    </button>
                    <button onClick={() => toggleDarkMode()}
                            className="w-9 h-9 rounded-full border border-white flex items-center justify-center text-white transition hover:bg-white/20">
                        {
                            darkMode ? <LightIcon/> : <MoonIcon/>
                        }
                    </button>
                </div>
            </div>
        </header>
    )
}