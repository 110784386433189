export default function ({exp}){
    return (
        <div className={`rounded-2xl lg:p-6 p-4 flex xl:flex-row lg:flex-col flex-col md:flex-row gap-4 xl:gap-8 lg:gap-6 items-start shadow-sm ${exp.bgClass}`}>
            <div className="flex-shrink-0">
                <img src={exp.image} className="xl:w-16 w-14" alt=""/>
            </div>
            <div className="flex-grow">
                <h4 className="text-gray-700 dark:text-slate-300 text-2xl font-semibold">
                    {exp.title}
                </h4>
                <p className="mt-3 text-gray-500 dark:text-slate-400 line-clamp-4">
                    {exp.description}
                </p>
            </div>
        </div>
    )
}