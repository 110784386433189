export default function ({edu}){
    return (
        <div className="rounded-2xl lg:p-5 p-4 shadow-sm bg-red-50 dark:bg-slate-800">
            <h4 className="text-gray-700 dark:text-slate-300 text-2xl font-semibold">{edu.degree}</h4>
            <h3 className="text-gray-500 dark:text-slate-400 text-lg italic mt-2">{edu.institute}</h3>
            <p className=" text-gray-500 dark:text-slate-400 text-xs">
                {edu.years}
            </p>
        </div>
    )
}