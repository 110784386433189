import Typed from "typed.js";
import {useEffect, useRef} from "react";
import {AnimateSection} from "../AnimateSection";

export default function Home() {

    const el = useRef(null);

    useEffect(() => {
        AnimateSection()
        const typed = new Typed(el.current, {
            strings: ["Designer.", "Developer."],
            startDelay: 300,
            typeSpeed: 70,
            backSpeed: 70,
            backDelay: 1000,
            loop: true,
        });

        // Destropying
        return () => {
            typed.destroy();
        };

    }, []);


    return (
        <div className="py-10 sm:py-20 lg:py-28 xl:py-32">
            <h2 className="text-gray-100 dark:text-slate-300 mb-3 text-3xl sm:text-6xl font-poppins font-semibold fade-in">Hello.</h2>
            <h3 className="sm:text-8xl text-5xl text-white dark:text-slate-200 font-bold font-poppins fade-in">
                I'm a <span className="text-primary-400">Web</span> <br/>
                <span ref={el}></span>
            </h3>
            <div className="fade-in">
                <p className="text-gray-300 text-xl mt-6 mb-12 font-poppins">Based on Los Angeles, US</p>
                <button
                    className="font-roboto text-primary-500 border-primary-500 transition border px-12 py-3 text-lg rounded-lg font-medium uppercase hover:bg-primary-500 hover:text-white">
                    Hire Me
                </button>
            </div>
        </div>
    )
}