import SectionTitle from "../components/SectionTitle";
import SubTitle from "../components/SubTitle";
import {nanoid} from "nanoid";
import {useEffect} from "react";
import {AnimateSection} from "../AnimateSection";
import Expertise from "../components/Expertise";
import Client from "../components/Client";

export default function About() {
    const expertises = [
        {
            id: nanoid(),
            bgClass: 'bg-indigo-50 dark:bg-slate-800',
            image: '/images/coding.png',
            title: 'Web Development',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis, dolore fugiat id illo modi quam quia temporibus ullam? Iure, voluptatibus.'
        },
        {
            id: nanoid(),
            bgClass: 'bg-blue-50 dark:bg-slate-800',
            image: '/images/mobile.png',
            title: 'App Development',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis, dolore fugiat id illo modi quam quia temporibus ullam? Iure, voluptatibus.'
        },
        {
            id: nanoid(),
            bgClass: 'bg-blue-50 dark:bg-slate-800',
            image: '/images/pen-tool.png',
            title: 'Graphics Design',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis, dolore fugiat id illo modi quam quia temporibus ullam? Iure, voluptatibus.'
        },
        {
            id: nanoid(),
            bgClass: 'bg-indigo-50 dark:bg-slate-800',
            image: '/images/management.png',
            title: 'Management',
            description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis, dolore fugiat id illo modi quam quia temporibus ullam? Iure, voluptatibus.'
        },
    ]

    const clients = [
        '/images/laravel.svg',
        '/images/mirage.svg',
        '/images/statamic.svg',
        '/images/statickit.svg',
        '/images/transistor.svg',
        '/images/tuple.svg'
    ]

    useEffect(() => {
        AnimateSection()
    }, [])

    return (
        <div id="about" className="scroll-mt-24">
            <SectionTitle>
                About Me
            </SectionTitle>
            <div className="section_wrapper fade-in">
                {/*Story*/}
                <div>
                    <SubTitle>
                        My Story.
                    </SubTitle>

                    <div className="xl:pr-40 lg:pr-10 space-y-4 mt-4 fade-in">
                        <p className="text-gray-500 dark:text-slate-400">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque beatae debitis, ea fugiat id
                            mollitia natus nostrum optio qui voluptatem!
                        </p>
                        <p className="text-gray-500 dark:text-slate-400">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum molestiae nulla, quaerat
                            reiciendis saepe ullam voluptates! Commodi, deserunt, dolores doloribus eligendi, et eveniet
                            fugit illo in laboriosam molestiae natus neque nobis optio provident quas. Accusamus aliquam
                            harum minima quisquam soluta?
                        </p>
                    </div>
                </div>

                {/*Expertise*/}
                <div className="fade-in">
                    <SubTitle>
                        My Expertise.
                    </SubTitle>

                    <div className="grid sm:grid-cols-2 lg:gap-4 gap-4 mt-5">
                        {
                            expertises.map(exp => (
                                <Expertise exp={exp} key={exp.id}/>
                            ))
                        }
                    </div>
                </div>

                {/*Clients*/}
                <div className="fade-in">
                    <SubTitle>
                        My Clients.
                    </SubTitle>
                    <div className="mt-5 grid grid-cols-2 gap-0.5 md:grid-cols-3 rounded-xl overflow-hidden shadow-sm">
                        {
                            clients.map((client, index) => (
                                <Client key={index} client={client}/>
                            ))
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}