import SectionTitle from "../components/SectionTitle";
import {useEffect, useState} from "react";
import CloseIcon from "../components/icons/CloseIcon";
import CheckIcon from "../components/icons/CheckIcon";
import {AnimateSection} from "../AnimateSection";

export default function Contact() {

    const [form, setForm] = useState({
        name: '',
        subject: '',
        email: '',
        message: '',
    })

    const [focused, setFocused] = useState({
        name: false,
        subject: false,
        email: false,
        message: false,
    })

    const [notify, setNotify] = useState(false)

    function submit(e) {
        e.preventDefault()
        // show notify
        setNotify(true)

        // reset form
        setForm(prevState => (
            {
                name: '',
                subject: '',
                email: '',
                message: '',
            }
        ))

        // hide notify
        setTimeout(() => {
            setNotify(false)
        }, 5000)
    }

    function handleFocusUpdate(name, value) {
        setFocused(prevState => (
            {
                ...prevState,
                [name]: value
            }
        ))
    }

    function handleFormUpdate(event) {
        console.log(event.target.value)
        const {name, value} = event.target
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }))
        console.log(form)
    }

    useEffect(() => {
        AnimateSection()
    }, [])

    return (
        <>
            <div id="contact" className="scroll-mt-24">
                <SectionTitle>
                    Contact Me
                </SectionTitle>

                <div className="section_wrapper fade-in">

                    <div className="grid sm:grid-cols-2 xl:gap-8 gap-4 md:gap-6 fade-in">
                        <a href="tel:1234567890"
                           className="shadow-sm bg-cyan-50 dark:bg-slate-800 p-6 flex flex-col justify-center items-center gap-3 rounded-xl cursor-pointer hover:border-primary-500 border border-transparent transition">
                            <img src="/images/phone.png" alt="phone" className="w-16"/>
                            <h3 className="text-xl text-gray-700 dark:text-slate-300">123 4567-890</h3>
                        </a>
                        <a href="mailto:example@mail.com"
                           className="shadow-sm bg-cyan-50 dark:bg-slate-800 p-6 flex flex-col justify-center items-center gap-3 rounded-xl cursor-pointer hover:border-primary-500 border border-transparent transition">
                            <img src="/images/email.png" alt="email" className="w-16"/>
                            <h3 className="text-xl text-gray-700 dark:text-slate-300">example@mail.com</h3>
                        </a>
                    </div>
                    <h4 className="text-gray-400 text-2xl font-medium fade-in">
                        Let's discuss about your ideas
                    </h4>
                    <form onSubmit={submit}
                          className="grid gap-4 sm:gap-6 p-5 sm:p-8 rounded-xl bg-blue-50 dark:bg-slate-800 !mt-4 fade-in">
                        <div className="grid sm:grid-cols-2 gap-4 sm:gap-6">
                            <div className="relative">
                                <label
                                    className={`block text-gray-500 dark:text-slate-400 mb-1 absolute left-3 top-3 px-1.5 transition-all pointer-events-none rounded-sm ${focused.name || form.name ? '!-top-2.5 text-sm bg-cyan-50 dark:bg-slate-900' : ''}`}
                                >
                                    Full Name
                                </label>
                                <input type="text" required
                                       name="name"
                                       value={form.name}
                                       onChange={handleFormUpdate}
                                       onFocus={() => handleFocusUpdate('name', true)}
                                       onBlur={() => handleFocusUpdate('name', false)}
                                       className="dark:bg-slate-900 dark:border-slate-700 dark:text-slate-300 block w-full text-sm rounded-lg border-gray-200 shadow-sm pb-2.5 pt-3.5 focus:ring-0 focus:border-primary-500"/>
                            </div>
                            <div className="relative">
                                <label
                                    className={`block text-gray-500 dark:text-slate-400 mb-1 absolute left-3 top-3 px-1.5 transition-all pointer-events-none rounded-sm ${focused.subject || form.subject ? '!-top-2.5 text-sm bg-cyan-50 dark:bg-slate-900' : ''}`}>
                                    Subject
                                </label>
                                <input type="text" required value={form.subject}
                                       name="subject"
                                       onChange={handleFormUpdate}
                                       onFocus={() => handleFocusUpdate('subject', true)}
                                       onBlur={() => handleFocusUpdate('subject', false)}
                                       className="dark:bg-slate-900 dark:border-slate-700 dark:text-slate-300 block w-full text-sm rounded-lg border-gray-200 shadow-sm pb-2.5 pt-3.5 focus:ring-0 focus:border-primary-500"/>
                            </div>
                        </div>
                        <div className="relative">
                            <label
                                className={`block text-gray-500 dark:text-slate-400 mb-1 absolute left-3 top-3 px-1.5 transition-all pointer-events-none rounded-sm ${focused.email || form.email ? '!-top-2.5 text-sm bg-cyan-50 dark:bg-slate-900' : ''}`}>
                                Email Address
                            </label>
                            <input type="email" required value={form.email}
                                   name="email"
                                   onChange={handleFormUpdate}
                                   onFocus={() => handleFocusUpdate('email', true)}
                                   onBlur={() => handleFocusUpdate('email', false)}
                                   className="dark:bg-slate-900 dark:border-slate-700 dark:text-slate-300 block w-full text-sm rounded-lg border-gray-200 shadow-sm pb-2.5 pt-3.5 focus:ring-0 focus:border-primary-500"/>
                        </div>
                        <div className="relative">
                            <label
                                className={`block text-gray-500 dark:text-slate-400 mb-1 absolute left-3 top-3 px-1.5 transition-all pointer-events-none rounded-sm ${focused.message || form.message ? '!-top-2.5 text-sm bg-cyan-50 dark:bg-slate-900' : ''}`}>
                                Message
                            </label>
                            <textarea required value={form.message}
                                      name="message"
                                      onChange={handleFormUpdate}
                                      onFocus={() => handleFocusUpdate('message', true)}
                                      onBlur={() => handleFocusUpdate('message', false)}
                                      className="dark:bg-slate-900 dark:border-slate-700 dark:text-slate-300 block w-full text-sm rounded-lg border-gray-200 shadow-sm pb-2.5 pt-3.5 focus:ring-0 focus:border-primary-500 h-40 resize-y">
                        </textarea>
                        </div>

                        <div className="mt-4">
                            <button type="submit"
                                    className="font-roboto bg-primary-500 hover:bg-primary-600 transition text-white px-8 py-2 rounded-lg font-medium uppercase">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div
                className={`fixed -left-full bottom-5 w-full max-w-xs overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all duration-300 ${notify ? '!left-5' : ''}`}>
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            <CheckIcon/>
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">Message successfully sent!</p>
                        </div>
                        <div className="ml-4 flex flex-shrink-0">
                            <button type="button" onClick={() => setNotify(false)}
                                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                <CloseIcon/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}