import {nanoid} from "nanoid";
import SectionTitle from "../components/SectionTitle";
import ArrowDownIcon from "../components/icons/ArrowDownIcon";
import LinkIcon from "../components/icons/LinkIcon";
import {useEffect, useState} from "react";
import {AnimateSection} from "../AnimateSection";
import Work from "../components/Work";


export default function Works() {
    const workCategories = ['All', 'Website', 'Graphic Design', 'UI/UX', 'Mobile App']
    const [activeCategory, setActiveCategory] = useState('All')

    const works = [
        {
            id: nanoid(),
            name: 'Food Delivery',
            category: 'Graphic Design',
            image: '/images/work-2.jpg',
            bgClass: 'bg-amber-50 dark:bg-slate-800'
        },
        {
            id: nanoid(),
            name: 'E-commerce Site',
            category: 'Website',
            image: '/images/work-6.jpg',
            bgClass: 'bg-blue-50 dark:bg-slate-800'
        },
        {
            id: nanoid(),
            name: 'Food Menu App',
            category: 'Mobile App',
            image: '/images/work-3.jpg',
            bgClass: 'bg-red-50 dark:bg-slate-800'
        },
        {
            id: nanoid(),
            name: 'Chat App',
            category: 'UI/UX',
            image: '/images/work-5.jpg',
            bgClass: 'bg-blue-50 dark:bg-slate-800'
        },
        {
            id: nanoid(),
            name: 'Zombie Fashion',
            category: 'Graphic Design',
            image: '/images/work-7.jpg',
            bgClass: 'bg-yellow-50 dark:bg-slate-800'
        },
        {
            id: nanoid(),
            name: 'Chat GPT',
            category: 'Mobile App',
            image: '/images/work-8.jpg',
            bgClass: 'bg-red-50 dark:bg-slate-800'
        },
    ]

    useEffect(() => {
        AnimateSection()
    }, [])

    return (
        <div id="works" className="scroll-mt-24">
            <SectionTitle>
                My Works
            </SectionTitle>

            <div className="section_wrapper fade-in">
                {/*Categories*/}
                <div>
                    {/*Desktop category*/}
                    <div className="hidden sm:flex flex-wrap items-center gap-2">
                        {
                            workCategories.map((category, index) => (
                                <button key={index}
                                        onClick={() => setActiveCategory(category)}
                                        className={`flex-shrink-0 text-gray-700 dark:text-slate-300 text-sm font-medium px-4 min-w-[70px] py-1 rounded-full hover:text-primary-500 dark:hover:text-primary-500 transition ${activeCategory === category ? 'bg-primary-500 !text-white hover:!text-white' : ''}`}>
                                    {category}
                                </button>
                            ))
                        }

                    </div>
                    {/*Mobile category*/}
                    <div className="flex justify-start sm:hidden w-max relative">
                        <div className="absolute pointer-events-none right-2.5 top-3 text-white">
                            <ArrowDownIcon/>
                        </div>
                        <select className="border-none bg-primary-500 text-white bg-none rounded-lg text-lg"
                                value={activeCategory} onChange={event => setActiveCategory(event.target.value)}>
                            {
                                workCategories.map((category, index) => (
                                    <option
                                        value={category}
                                        key={index}>
                                        {category === 'All' ? 'All Works' : category}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                {/*Works grid*/}
                <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4 !mt-6">
                    {
                        works.map(work => (
                            <Work key={work.id} activeCategory={activeCategory} work={work}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}