export default function ({skill}){
    return (
        <div>
            <div className="flex justify-between">
                <p className="text-gray-700 font-semibold text-sm">
                    {skill.name}
                </p>
                <p className="text-sm text-gray-700 font-semibold pr-2">
                    {skill.percent}%
                </p>
            </div>
            <div
                className="h-1 rounded-full overflow-hidden relative w-full bg-gray-200 dark:bg-slate-700 mt-1">
                <div className={`absolute left-0 top-0 h-full ${skill.bgClass}`}
                     style={{width: `${skill.percent}%`}}></div>
            </div>
        </div>
    )
}