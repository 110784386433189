import React, {useState} from 'react';
import Router from './Router';
import SideBar from './components/SideBar'
import NavBar from "./components/NavBar";

function App() {
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') && localStorage.getItem('darkMode') === 'true')

    function toggleDarkMode() {
        setDarkMode(!darkMode)
        localStorage.setItem('darkMode', !darkMode)
    }

    return (
        <>
            <div className={`min-h-screen bg-fixed bg-center bg-cover bg-gray-100 relative ${darkMode && 'dark'}`}
                 style={{backgroundImage: `url('images/animate-bg.svg')`}}>
                {
                    darkMode && <div className="absolute inset-0 bg-black/30"></div>
                }

                <div className="relative">
                    <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode}/>

                    {/*Main container*/}
                    <div
                        className="container flex lg:flex-row flex-col items-start lg:gap-6 gap-6 2xl:gap-12 pb-16 mt-12 sm:mt-0">

                        {/*Left side*/}
                        <div className="flex-grow space-y-8 w-full mt-0 sm:mt-12 lg:mt-20">
                            <Router/>
                        </div>

                        {/*right side*/}
                        <SideBar/>

                    </div>
                </div>

            </div>
        </>
    );
}

export default App;
